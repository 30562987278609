import localConf from './local.json';
import testConf from './test.json';
import prodConf from './prod.json';
import adminConf from './admin.json';
import networkCloudConf from './network/cloud.json';
import networkLocalConf from './network/local.json';
import version from './version/version.json';
import EnvProvider from './EnvProvider';
import ConfigSchema from './ConfigSchema';

/**
 * @param {EnvProvider} envProvider 
 * @returns {ConfigSchema}
 */
const getEnvironment = (envProvider = new EnvProvider()) => {
    let config;

    if(envProvider.NODE_ENV === 'development') {
        if(envProvider.REACT_APP_DEV_ENV === 'admin') {
            config = adminConf;
        }
        else {
            config = localConf;
        }
    }
    else if(envProvider.NODE_ENV === 'test') {
        config = testConf;
    }
    else if(envProvider.NODE_ENV === 'production') {
        config = prodConf;
    }
    
    if(!config){
        //assume local for no argument and not test
        config = localConf;
    }
    
    //network config
    let networkConfig;
    if(envProvider.REACT_APP_NETWORK_ENV === 'cloud' || envProvider.NODE_ENV === 'production') {
        networkConfig = networkCloudConf;
    }
    else {
        networkConfig = networkLocalConf;
    }

    const finalConfig = {...config, ...networkConfig, ...version};
    return finalConfig;
}

export default getEnvironment;
import ViewModel, { LiveData } from 'react-livedata';
import ResultsStorePublisher from './ResultsStorePublisher';
import BackPressedReceiver from '../finalResults/BackPressedReceiver';
import getEnvironment from '../config/getEnvironment';

export const PAGES = Object.freeze({
    EVENT_FORM: Symbol(),
    RESULTS: Symbol(),
});

//declare your livedata properties and initial/default property values here
export const liveData = Object.freeze({
    currentPage: new LiveData(PAGES.EVENT_FORM),
    lastPublishedResults: new LiveData(''),
});

export default class PageCoordinatorViewModel extends ViewModel {
    /**
     * @param {React.Component} reactObj 
     */
    constructor(reactObj) {
        super(reactObj, liveData);
        const conf = getEnvironment();
        if(conf.isFormMocksEnabled) {
            const mockedResponse = conf.formMocks.results;
            this.setLiveData(liveData.lastPublishedResults, mockedResponse);
        }
    }

    /**
     * @see {ResultsStorePublisher} @override
    */
   onFinalResultsReceived(results) {
       console.error('onFinalResultsReceived: ', results)
       //unit test on results with new page, and handing results down
       this.setLiveData(liveData.currentPage, PAGES.RESULTS);
       this.setLiveData(liveData.lastPublishedResults, results);
    }
    
    /**
     * @see {BackPressedReceiver} @override
     */
    
    onBackPressed() {
        console.log('onBackPressed')
        this.setLiveData(liveData.currentPage, PAGES.EVENT_FORM);
    }


}
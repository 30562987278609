import LocalStorageProvider from "./LocalStorageProvider";

export default class LocalStorageProviderImpl extends LocalStorageProvider {
    getItem(key) {
        return localStorage.getItem(key);
    }
    
    setItem(key, value) {
        localStorage.setItem(key, value);
    }
}
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import PageCoordinator from '../pageCoordinator/PageCoordinator';
import Version from '../Version';
import BackdropColor from '../BackdropColor';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div id="App">
        <PageCoordinator />
        <Version />
        <BackdropColor />
      </div>
    </ThemeProvider>
  );
}
export default App;
import { Box, Slider } from '@mui/material';

class SliderInputPropsSchema {
    constructor() {
        this.labelAtStart = '';
        this.labelAtEnd = '';
        this.minValue = 0;
        this.maxValue = 0;
        this.valueBetweenMarks = 0;
        this.defaultValue = 0;
        this.isEnabled = true;
    }
    
    accessibilityValuesForMarks(){ };
    onChange(newValue){ };
}

/**
 * 
 * @param {SliderInputPropsSchema} props 
 * @returns 
 */
const TwoSideSliderInput = (props) => {
    return <>
        <div style={{ margin: '20px 0', flexDirection: 'row', display: 'flex' }}>
            <div style={{ marginRight: '20px', alignSelf: "end" }} className="form-label">
                <SliderInputLabel labelText={props.labelAtStart} isSideEnabled={props.currentValue === 1} />
            </div>
            <Box sx={{ width: 200 }}>
                <Slider
                    aria-label="Tone"
                    defaultValue={props.defaultValue}
                    getAriaValueText={props.accessibilityValuesForMarks}
                    valueLabelDisplay="auto"
                    step={props.valueBetweenMarks}
                    marks
                    min={1}
                    max={2}
                    onChange={(event, newValue) => {
                        props.onChange(newValue);
                    }}
                    disabled={!props.isEnabled}
                />
            </Box>
            <div style={{ marginLeft: '20px', alignSelf: "end" }} className="form-label">
                <SliderInputLabel labelText={props.labelAtEnd} isSideEnabled={props.currentValue === 2} />
            </div>
        </div>
    </>
}

const SliderInputLabel = (props) => {
    if(props.isSideEnabled) {
        return <span><strong>{props.labelText}</strong></span>
    }
    else {
        return <span>{props.labelText}</span>
    }

}

export default TwoSideSliderInput;
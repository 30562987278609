function BackdropColor(props) {
    return (
        <div style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgb(50,50,50)',
        }}>
        </div >
    );
}

export default BackdropColor;
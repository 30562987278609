import getEnvironment from "./config/getEnvironment";
const conf = getEnvironment();

function Version(props) {
    return (
        <>
            <div style={{
                padding: '20px',
                textAlign: 'center'}}>{`Version: ${conf.envName} ${conf.version}`}</div >
        </>
    );
}

export default Version;
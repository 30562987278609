/**
 * hook into browser local storage
 */
export default class LocalStorageProvider {
    /**
     * @param {string} key 
     */
    getItem(key) {}
    /**
     * 
     * @param {string} key 
     * @param {any} value 
     */
    setItem(key, value) {}
}
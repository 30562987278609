import React from 'react';
import BackPressedReceiver from './BackPressedReceiver';
import { FormControl, Box, Grid, Container, Card, CardContent, CardActions, Button, Typography, TextField, Modal } from '@mui/material';
import { ArrowBack, CopyAll, UndoRounded } from '@mui/icons-material';
import FinalResultsFormViewModel, { liveData } from './FinalResultsFormViewModel';

class FinalResultsFormPropsSchema {
    constructor() {
        this.results = '';
        this.backPressedReceiver = new BackPressedReceiver()
    }
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const buttonStyles = {
    margin: '15px 5px 0px 5px',
};

export default class FinalResultsForm extends React.Component {
    /**
     * 
     * @param {FinalResultsFormPropsSchema} props 
     * @returns 
     */
    constructor(props) {
        super(props)
        this.state = {};
        this.viewmodel = new FinalResultsFormViewModel(this, props.results, this.props.backPressedReceiver)
        
    }


    render() {
        const ConfirmRevertModal = <Modal
            open={this.viewmodel.getLiveData(liveData.isShowingRevertResultsModal)}
            onClose={() => { this.viewmodel.onRevertModalClosed() }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Revert Results
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Are you sure you want to revert your results? You will lose your current edits permanantly.
                </Typography>
                <Button sx={buttonStyles} onClick={() => { this.viewmodel.onRevertModalClosed() }} >Cancel</Button>
                <Button sx={buttonStyles} onClick={() => { this.viewmodel.onRevertToOriginalResultsConfirmed() }} variant="outlined" color="error">Confirm Revert</Button>
            </Box>
        </Modal>
        
        const ConfirmBackNavigationModal = <Modal
            open={this.viewmodel.getLiveData(liveData.isShowingGoBacktoEventFormModal)}
            onClose={() => { this.viewmodel.onGoBackToEventFormModalClosed() }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Go Back
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Are you sure you want to go back? You will lose your current edits permanantly.
                </Typography>
                <Button sx={buttonStyles} onClick={() => { this.viewmodel.onGoBackToEventFormModalClosed() }} >Cancel</Button>
                <Button sx={buttonStyles} onClick={() => { this.viewmodel.onGoBackToEventFormConfirmed() }} variant="outlined" color="error">Go Back</Button>
            </Box>
        </Modal>
        return (<>
            {ConfirmBackNavigationModal}
            {ConfirmRevertModal}
            <Container sx={{padding: '20px'}}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Button onClick={() => { this.viewmodel.onBackClicked() }}>
                                <ArrowBack />
                            </Button>
                        </Grid>
                        <Grid item xs={10}>
                            <h2>Event Generated</h2>
                        </Grid>
                    </Grid>
                </Box>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent sx={{}}>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                value={this.viewmodel.getLiveData(liveData.results)}
                                onChange={(e) => {
                                    this.viewmodel.updateResults(e.target.value);
                                }}
                            />
                        </FormControl>
                    </CardContent>
                    <CardActions>
                        <Button onClick={() => { navigator.clipboard.writeText(this.viewmodel.getLiveData(liveData.results)) }} size="small"><CopyAll />Copy All</Button>
                    </CardActions>
                    <CardActions>
                        <Button onClick={() => { this.viewmodel.onRevertToOriginalResultsClicked() }} size="small"><UndoRounded />Revert to Original</Button>
                    </CardActions>
                </Card>
            </Container>
        </>);
    }

}
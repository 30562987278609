import axios from 'axios';
import getEnvironment from '../config/getEnvironment';
const conf = getEnvironment();

/**
 * calls the chat GPT service
 */
class EmailRegistrationServiceImpl {
    async registerEmailServiceCall(payload) {
        console.log('EmailRegistrationServiceImpl registerEmailServiceCall()')
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: `${conf.baseUrl}/registerEmail`,
                data: payload
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                console.error(error)
                reject(error);
            });
        });
    }
}

export default EmailRegistrationServiceImpl;
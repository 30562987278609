import moment from 'moment-timezone';

/**
* @param {string} date 
* @param {string} time 
* @param {string} timezone 
* @returns 
*/
export function getFormSubmittableDate(date, time, timezone) {
    //get date from input
    let finalTimezone = 'America/New_York';
    if (timezone === 'PST') {
        finalTimezone = 'America/Los_Angeles';
    }
    else if (timezone === 'MST') {
        finalTimezone = 'America/Denver';
    }
    else if (timezone === 'CST') {
        finalTimezone = 'America/Chicago';
    }
    else if (timezone === 'EST') {
        finalTimezone = 'America/New_York';
    }
    const finalTime = moment.tz(`${date} ${time}`, finalTimezone);
    return finalTime.format()
}
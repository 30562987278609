import { Card, Placeholder } from 'react-bootstrap';

/**
 * enum for results area states
 */
export const ResultsAreaUI = Object.freeze({
    Empty: Symbol('Empty'),
    Placeholder: Symbol('Placeholder'),
    Text: Symbol('Text'),
    Error: Symbol('Error')
});

class ResultsAreaPropsSchema {
    constructor() {
        this.resultsAreaType = ResultsAreaUI.Empty;
        this.textAreaWidth = '';
        this.chatResponse = '';
        this.errorText = '';
    }
}

/**
 * 
 * @param {ResultsAreaPropsSchema} props 
 * @returns ResultsArea Component
 */
function ResultsArea(props) {
    if (props.resultsAreaType === ResultsAreaUI.Empty) {
        return <div />
    }
    else if (props.resultsAreaType === ResultsAreaUI.Placeholder) {
        return (
            <Card style={{ width: '32rem' }}>
                <Card.Body>
                    <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                        <Placeholder xs={6} /> <Placeholder xs={8} />
                    </Placeholder>
                    <div>Generating an event can take 60 seconds.</div>
                </Card.Body>
            </Card>
        );
    }
    else if (props.resultsAreaType === ResultsAreaUI.Text) {
        return (
            <Card style={{ width: props.textAreaWidth, whiteSpace: 'pre-wrap' }}>
                <Card.Body>
                    {props.chatResponse}
                </Card.Body>
            </Card>
        )
    }
    else if (props.resultsAreaType === ResultsAreaUI.Error) {
        return (
            <Card style={{ width: '22rem' }}>
                <Card.Body>
                    {props.errorText}
                </Card.Body>
            </Card>
        )
    }
}

export default ResultsArea;
import PageCoordinatorViewModel, { PAGES, liveData } from "./PageCoordinatorViewModel";
import EventForm from '../eventForm/EventForm';
import FinalResultsForm from '../finalResults/FinalResultsForm';
import React from "react";

export default class PageCoordinator extends React.Component {
    constructor(props) {
        super(props)
        this.state = {};
        this.viewModel = new PageCoordinatorViewModel(this)
    }

    render() {
        let pageShowing = <></>

        if (this.viewModel.getLiveData(liveData.currentPage) === PAGES.EVENT_FORM) {
            pageShowing = <EventForm resultStorePublisher={this.viewModel} />;
        }
        if (this.viewModel.getLiveData(liveData.currentPage) === PAGES.RESULTS) {
            pageShowing = <FinalResultsForm results={this.viewModel.getLiveData(liveData.lastPublishedResults)}
                pageCoordinator={this.viewModel}
                backPressedReceiver={this.viewModel}
            />;
        }

        return (
            <>
                {pageShowing}
            </>
        )
    }
}
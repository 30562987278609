import React from 'react';
import ReactDOM from 'react-dom/client';
import './reactSetup/index.css';
import App from './reactSetup/App';
import getEnvironment from './config/getEnvironment';
const conf = getEnvironment();
console.log('Env: ', conf.envName, conf.networkName);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
import ViewModel, { LiveData } from 'react-livedata';
import getEnvironment from '../config/getEnvironment';
import { ResultsAreaUI } from '../ResultsArea';
import EmailRegistrationResultsSchema from './EmailRegistrationResultsSchema';
import { getFormSubmittableDate } from '../TimeTransformer';
import LocalStorageProvider from './localstorage/LocalStorageProvider';
import ResultsStorePublisher from '../pageCoordinator/ResultsStorePublisher';
import EventFormPayloadProvider from '../adminForm/EventFormPayloadProvider';

export const INPUT_VOICE_STYLE_DEFAULT = 1;
export const INPUT_VOICE_STYLE_MAX = 2;

export const liveData = Object.freeze({
    isAwaitingEventGenerateResponse: new LiveData(false, 'isAwaitingEventGenerateResponse'),
    isEventFormInputEnabled: new LiveData(true, 'isEventFormInputEnabled'),
    isEventFormSubmitEnabled: new LiveData(false, 'isEventFormSubmitEnabled'),
    resultsArea: new LiveData(ResultsAreaUI.Empty),
    chatResponse: new LiveData(''),
    errorText: new LiveData('', 'errorText'),
    inputVoiceStyle: new LiveData(INPUT_VOICE_STYLE_DEFAULT, 'inputVoiceStyle'),
    inputEventName: new LiveData('', 'inputEventName'),
    inputCompanyName: new LiveData('', 'inputCompanyName'),
    inputCompanyURL: new LiveData('', 'inputCompanyURL'),
    inputEventDescription: new LiveData('', 'inputEventDescription'),
    inputUserBenefit: new LiveData('', 'inputUserBenefit'),
    inputEventType: new LiveData('', 'inputEventType'),
    inputEventDate: new LiveData('', 'inputEventDate'),
    inputEventTimeStart: new LiveData('', 'inputEventTimeStart'),
    inputEventTimeEnd: new LiveData('', 'inputEventTimeEnd'),
    inputEventTimeZone: new LiveData('', 'inputEventTimeZone'),
    inputEventLocation: new LiveData('', 'inputEventLocation'),
    inputRegistrationUrl: new LiveData('', 'inputRegistrationUrl'),
    inputIsChannelMixEmailEnabled: new LiveData(false, 'inputIsChannelMixEmailEnabled'),
    inputIsChannelMixLinkedInEnabled: new LiveData(false, 'inputIsChannelMixLinkedInEnabled'),
    isUserDetailsFormModalShowing: new LiveData(false, 'isUserDetailsFormModalShowing'),
    isModalButtonLoading: new LiveData(false, 'isModalButtonLoading'),
    isModalInputEnabled: new LiveData(false, 'isModalInputEnabled'),
    isModalSubmitEnabled: new LiveData(false, 'isModalSubmitEnabled'),
    isAwaitingForRegistrationResponse: new LiveData(false),
    inputEmail: new LiveData('', 'inputEmail'),
    inputName: new LiveData('', 'inputName'),
    inputSalesTeamNumber: new LiveData(0, 'inputSalesTeamNumber'),
    isResultsModalShowing: new LiveData(false),
    isPleaseWaitLoadingMessageShowing: new LiveData(false),
});

export default class EventFormViewModel extends ViewModel {

    /**
     * @param {React.Component} reactObj contains the state for our object
     * @param {EmailRegistrationServiceImpl} registerEmailService 
     * @param {EventGenerateServiceImpl} eventGenerateService 
     * @param {LocalStorageProvider} localStorageProvider 
     * @param {ResultsStorePublisher} resultsStorePublisher 
     */
    constructor(reactObj, registerEmailService, eventGenerateService, localStorageProvider, resultsStorePublisher) {
        super(reactObj, liveData);
        this.registerEmailService = registerEmailService;
        this.eventGenerateService = eventGenerateService;
        this.localStorageProvider = localStorageProvider;
        this.resultsStorePublisher = resultsStorePublisher;
        this.conf = getEnvironment();

        this.initializeSlidersUI();
        this.restoreEventFormState();

        this.evaluateFormForSubmit();
    }

    initializeSlidersUI() {
        this.restoreStateForLiveData(liveData.inputVoiceStyle);
        if(this.getLiveData(liveData.inputVoiceStyle) === undefined) {
            this.setLiveData(liveData.inputVoiceStyle, INPUT_VOICE_STYLE_DEFAULT);
            this.inputSliderStartingVoiceStyle = INPUT_VOICE_STYLE_DEFAULT;
        }
        else {
            this.inputSliderStartingVoiceStyle = this.getLiveData(liveData.inputVoiceStyle);
        }
    }

    restoreEventFormState() {
        
        this.restoreStateForLiveData(liveData.inputEventName);
        this.restoreStateForLiveData(liveData.inputCompanyName);
        this.restoreStateForLiveData(liveData.inputCompanyURL);
        this.restoreStateForLiveData(liveData.inputEventDescription);
        this.restoreStateForLiveData(liveData.inputUserBenefit);
        this.restoreStateForLiveData(liveData.inputEventType);
        this.restoreStateForLiveData(liveData.inputEventTimeStart);
        this.restoreStateForLiveData(liveData.inputEventTimeEnd);
        this.restoreStateForLiveData(liveData.inputEventTimeZone);
        this.restoreStateForLiveData(liveData.inputEventLocation);
        this.restoreStateForLiveData(liveData.inputRegistrationUrl);
        this.restoreStateForBooleanLiveData(liveData.inputIsChannelMixEmailEnabled);
        this.restoreStateForBooleanLiveData(liveData.inputIsChannelMixLinkedInEnabled);
        
        this.restoreStateForLiveData(liveData.inputEventDate);
        if (!this.getLiveData(liveData.inputEventDate)) {
            //date
            const date = new Date();
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); //note that January is 0
            const yyyy = date.getFullYear();
            const today = `${yyyy}-${mm}-${dd}`;
            this.setFormInput(liveData.inputEventDate, today)
        }
        
        //restore modal state
        this.restoreStateForLiveData(liveData.inputName);
        this.restoreStateForLiveData(liveData.inputEmail);
        this.restoreStateForLiveData(liveData.inputSalesTeamNumber);
    }

    /**
     * @param {LiveData} liveData 
     */
    restoreStateForLiveData(liveData) {
        let restorationValue = this.localStorageProvider.getItem(liveData.key.description);
        if (restorationValue !== undefined) {
            this.setFormInput(liveData, restorationValue)
        }
    }

    /**
     * @param {LiveData} liveData 
     */
    restoreStateForBooleanLiveData(liveData) {
        let restorationValue = this.localStorageProvider.getItem(liveData.key.description);
        if (restorationValue) {
            if (restorationValue === 'true') {
                this.setFormInput(liveData, true);
            }
            if (restorationValue === 'false') {
                this.setFormInput(liveData, false);
            }
        }

    }

    evaluateFormForSubmit() {
        if (this.isEventFormFilledoutCompletely() && !this.getLiveData(liveData.isAwaitingEventGenerateResponse)) {
            this.setLiveData(liveData.isEventFormSubmitEnabled, true);
        }
        else {
            this.setLiveData(liveData.isEventFormSubmitEnabled, false);
        }
    }

    isEventFormFilledoutCompletely() {
        if (this.getLiveData(liveData.inputEventName)
            && this.getLiveData(liveData.inputCompanyName)
            && this.getLiveData(liveData.inputCompanyURL)
            && this.getLiveData(liveData.inputEventDescription)
            && this.getLiveData(liveData.inputUserBenefit)
            && this.getLiveData(liveData.inputEventType)
            && this.getLiveData(liveData.inputEventDate)
            && this.getLiveData(liveData.inputEventTimeStart)
            && this.getLiveData(liveData.inputEventTimeEnd)
            && this.getLiveData(liveData.inputEventTimeZone)
            && this.getLiveData(liveData.inputEventLocation)
            && this.getLiveData(liveData.inputRegistrationUrl)) {
            return true;
        }
        return false;
    }

    /**
     * 
     * @param {LiveData} liveDataToUpdate property state reference
     * @param {any} value property value
     */
    setFormInput(liveDataToUpdate, value) {
        if (liveDataToUpdate === liveData.inputEventName
            || liveDataToUpdate === liveData.inputCompanyName
            || liveDataToUpdate === liveData.inputCompanyURL
            || liveDataToUpdate === liveData.inputEventDescription
            || liveDataToUpdate === liveData.inputUserBenefit
            || liveDataToUpdate === liveData.inputEventType
            || liveDataToUpdate === liveData.inputEventDate
            || liveDataToUpdate === liveData.inputEventTimeStart
            || liveDataToUpdate === liveData.inputEventTimeEnd
            || liveDataToUpdate === liveData.inputEventTimeZone
            || liveDataToUpdate === liveData.inputEventLocation
            || liveDataToUpdate === liveData.inputRegistrationUrl
            || liveDataToUpdate === liveData.inputEmail
            || liveDataToUpdate === liveData.inputName) {
            if (typeof value !== 'string') {
                value = '';
            }
        }
        else if (liveDataToUpdate === liveData.inputVoiceStyle
            || liveDataToUpdate === liveData.inputSalesTeamNumber
        ) {
            value = this.getNumericFromStringInputOrDefault(value, liveDataToUpdate);
            if (liveDataToUpdate === liveData.inputVoiceStyle) {
                if(value > INPUT_VOICE_STYLE_MAX) {
                    value = INPUT_VOICE_STYLE_MAX;
                }
            }
        }
        else if (liveDataToUpdate === liveData.inputIsChannelMixEmailEnabled ||
            liveDataToUpdate === liveData.inputIsChannelMixLinkedInEnabled) {
            if (typeof value !== 'boolean') {
                value = false;
            }
        }
        this.setLiveData(liveDataToUpdate, value);
        this.evaluateFormForSubmit();
        this.evaluateModalForSubmit();
    }

    /**
     * @param {*} newValue 
     * @param {LiveData} livedataWithDefaultValue 
     * @returns {number}
     */
    getNumericFromStringInputOrDefault(newValue, livedataWithDefaultValue) {
        let value = this.getLiveData(livedataWithDefaultValue.defaultValue)
        if (newValue) {
            const numVal = parseInt(newValue);
            if (typeof numVal === 'number') {
                value = numVal;
            }
        }
        return value;
    }

    onCloseMoreUserInfoModalClicked() {
        this.setLiveData(liveData.isUserDetailsFormModalShowing, false);
        this.setLiveData(liveData.isEventFormInputEnabled, true);
        this.setLiveData(liveData.isEventFormSubmitEnabled, true);
    }

    onCloseResultsModalClicked() {
        console.log('onCloseResultsModalClicked()');
        if (this.isAwaitingNetworkResponse()) {
            console.log('showing message');
            this.showLoadingMessage()
        }
        else {
            console.log('not loading');
            this.setLiveData(liveData.isResultsModalShowing, false);
            this.setLiveData(liveData.isEventFormInputEnabled, true);
            this.setLiveData(liveData.isEventFormSubmitEnabled, true);
        }
    }

    isAwaitingNetworkResponse() {
        if (this.getLiveData(liveData.isAwaitingEventGenerateResponse) || this.getLiveData(liveData.isAwaitingForRegistrationResponse)) {
            return true;
        }
        return false;
    }

    showLoadingMessage() {
        this.setLiveData(liveData.isPleaseWaitLoadingMessageShowing, true);
    }

    hideLoadingMessage() {
        this.setLiveData(liveData.isPleaseWaitLoadingMessageShowing, false);
    }

    evaluateModalForSubmit() {
        //MODAL
        if (this.getLiveData(liveData.isAwaitingEventGenerateResponse) || this.getLiveData(liveData.isAwaitingForRegistrationResponse)) {
            this.setLiveData(liveData.isModalInputEnabled, false)
        }
        else {
            this.setLiveData(liveData.isModalInputEnabled, true)
        }

        if (this.getLiveData(liveData.inputEmail)
            && this.getLiveData(liveData.inputName)
            && (this.getLiveData(liveData.inputSalesTeamNumber) >= 0)
            && !this.getLiveData(liveData.isAwaitingEventGenerateResponse)
            && !this.getLiveData(liveData.isAwaitingForRegistrationResponse)) {
            this.setLiveData(liveData.isModalSubmitEnabled, true);
        }
        else {
            this.setLiveData(liveData.isModalSubmitEnabled, false);
        }

        if (this.getLiveData(liveData.isAwaitingForRegistrationResponse) || this.getLiveData(liveData.isAwaitingEventGenerateResponse)) {
            this.setLiveData(liveData.isModalButtonLoading, true);
        }
        else {
            this.setLiveData(liveData.isModalButtonLoading, false);
        }
    }

    /**
     * opens modal to get final information
     */
    onSubmitEventFormClicked() {
        this.saveEventFormStateForRestoration()
        if (this.isEventFormFilledoutCompletely()) {
            this.setLiveData(liveData.isUserDetailsFormModalShowing, true);
            this.setLiveData(liveData.isEventFormInputEnabled, false);
            this.setLiveData(liveData.isEventFormSubmitEnabled, false);
            this.evaluateModalForSubmit();
        }
        else {
            this.setLiveData(liveData.isEventFormInputEnabled, true);
            this.setLiveData(liveData.isEventFormSubmitEnabled, false);
            this.setLiveData(liveData.resultsArea, ResultsAreaUI.Error);
            this.setLiveData(liveData.errorText, 'Please enter all required fields.');
        }
    }

    saveEventFormStateForRestoration() {
        this.localStorageProvider.setItem(liveData.inputVoiceStyle.key.description, this.getLiveData(liveData.inputVoiceStyle));
        this.localStorageProvider.setItem(liveData.inputEventName.key.description, this.getLiveData(liveData.inputEventName));
        this.localStorageProvider.setItem(liveData.inputCompanyName.key.description, this.getLiveData(liveData.inputCompanyName));
        this.localStorageProvider.setItem(liveData.inputCompanyURL.key.description, this.getLiveData(liveData.inputCompanyURL));
        this.localStorageProvider.setItem(liveData.inputEventDescription.key.description, this.getLiveData(liveData.inputEventDescription));
        this.localStorageProvider.setItem(liveData.inputUserBenefit.key.description, this.getLiveData(liveData.inputUserBenefit));
        this.localStorageProvider.setItem(liveData.inputEventType.key.description, this.getLiveData(liveData.inputEventType));
        this.localStorageProvider.setItem(liveData.inputEventDate.key.description, this.getLiveData(liveData.inputEventDate));
        this.localStorageProvider.setItem(liveData.inputEventTimeStart.key.description, this.getLiveData(liveData.inputEventTimeStart));
        this.localStorageProvider.setItem(liveData.inputEventTimeEnd.key.description, this.getLiveData(liveData.inputEventTimeEnd));
        this.localStorageProvider.setItem(liveData.inputEventTimeZone.key.description, this.getLiveData(liveData.inputEventTimeZone));
        this.localStorageProvider.setItem(liveData.inputEventLocation.key.description, this.getLiveData(liveData.inputEventLocation));
        this.localStorageProvider.setItem(liveData.inputRegistrationUrl.key.description, this.getLiveData(liveData.inputRegistrationUrl));
        this.localStorageProvider.setItem(liveData.inputIsChannelMixEmailEnabled.key.description, this.getLiveData(liveData.inputIsChannelMixEmailEnabled));
        this.localStorageProvider.setItem(liveData.inputIsChannelMixLinkedInEnabled.key.description, this.getLiveData(liveData.inputIsChannelMixLinkedInEnabled));
    }

    /**
     * makes network call to ChatGPT, orchestrats loading UI
    */
    async registerEmail() {
        this.saveModalFormStateForRestoration();
        //validation before we start networking call
        if (!this.getLiveData(liveData.inputEmail)) {
            this.setLiveData(liveData.isAwaitingEventGenerateResponse, false);
            this.setLiveData(liveData.resultsArea, ResultsAreaUI.Error);
            this.setLiveData(liveData.errorText, 'Please enter something event2');
            return;
        }

        this.setLiveData(liveData.isResultsModalShowing, true);
        this.setLiveData(liveData.resultsArea, ResultsAreaUI.Placeholder);
        this.setLiveData(liveData.isAwaitingForRegistrationResponse, true);
        this.setLiveData(liveData.isEventFormInputEnabled, false);
        this.setLiveData(liveData.isEventFormSubmitEnabled, false);
        this.setLiveData(liveData.isUserDetailsFormModalShowing, false);

        try {
            console.log('registering Email...');
            const emailRegistrationPayload = {
                email: this.getLiveData(liveData.inputEmail),
                name: this.getLiveData(liveData.inputName),
                salesTeamNumber: parseInt(this.getLiveData(liveData.inputSalesTeamNumber)),
            };
            const emailRegistrationResponse = await this.registerEmailService.registerEmailServiceCall(emailRegistrationPayload);
            await this.onEmailRegistrationResults(emailRegistrationResponse)
        }
        catch (err) {
            console.error('ERROR while communicating with email registration service.');
            console.error(err);
            this.showNetworkingErrorState()
        }
    }

    saveModalFormStateForRestoration() {
        this.localStorageProvider.setItem(liveData.inputSalesTeamNumber.key.description, this.getLiveData(liveData.inputSalesTeamNumber));
        this.localStorageProvider.setItem(liveData.inputName.key.description, this.getLiveData(liveData.inputName));
        this.localStorageProvider.setItem(liveData.inputEmail.key.description, this.getLiveData(liveData.inputEmail));
    }

    /**
     * @param {EmailRegistrationResultsSchema} emailRegistrationResults 
    */
    async onEmailRegistrationResults(emailRegistrationResults) {
        console.log('EventFormViewModel.onEmailRegistrationComplete: ', emailRegistrationResults);
        this.setLiveData(liveData.isAwaitingForRegistrationResponse, false);
        if (this.isEmailGenerateResultsSuccess(emailRegistrationResults)) {
            await this.generateEventForRegisteredEmailId(emailRegistrationResults.id);
        }
        else {
            this.showNetworkingErrorState();
        }
    }

    /**
     * @param {EmailRegistrationResultsSchema} emailRegistrationResults 
     */
    isEmailGenerateResultsSuccess(emailRegistrationResults) {
        if (typeof emailRegistrationResults !== 'object' || !emailRegistrationResults.id) {
            return false;
        }
        return true;
    }

    /**
     * @param {string} id 
     */
    async generateEventForRegisteredEmailId(id) {
        this.setLiveData(liveData.isResultsModalShowing, true);
        this.setLiveData(liveData.isAwaitingEventGenerateResponse, true);

        try {
            const generateEventPayload = this.getEventGeneratePayload();
            console.log('calling out to chat service...');
            let chatResponse = await this.eventGenerateService.eventGenerationServiceCall(id, generateEventPayload)
            this.onEventGenerateResults(chatResponse);
        }
        catch (err) {
            this.showNetworkingErrorState();
        }
    }

    /**
     * @see {EventFormPayloadProvider}
     * @returns 
     */
    getEventGeneratePayload() {
        const timeStart = getFormSubmittableDate(this.getLiveData(liveData.inputEventDate), this.getLiveData(liveData.inputEventTimeStart), this.getLiveData(liveData.inputEventTimeZone));
        const timeEnd = getFormSubmittableDate(this.getLiveData(liveData.inputEventDate), this.getLiveData(liveData.inputEventTimeEnd), this.getLiveData(liveData.inputEventTimeZone));

        const eventPayload = {
            voiceStyle: this.getLiveData(liveData.inputVoiceStyle),
            eventName: this.getLiveData(liveData.inputEventName),
            companyName: this.getLiveData(liveData.inputCompanyName),
            companyURL: this.getLiveData(liveData.inputCompanyURL),
            description: this.getLiveData(liveData.inputEventDescription),
            userBenefit: this.getLiveData(liveData.inputUserBenefit),
            type: this.getLiveData(liveData.inputEventType),
            date: this.getLiveData(liveData.inputEventDate),
            timeStart,
            timeEnd,
            location: this.getLiveData(liveData.inputEventLocation),
            registrationUrl: this.getLiveData(liveData.inputRegistrationUrl),
            isChannelMixEmail: this.getLiveData(liveData.inputIsChannelMixEmailEnabled),
            isChannelMixLinkedIn: this.getLiveData(liveData.inputIsChannelMixLinkedInEnabled)
        };

        return eventPayload;
    }

    showNetworkingErrorState() {
        this.setLiveData(liveData.resultsArea, ResultsAreaUI.Error);
        this.setLiveData(liveData.errorText, 'Oh no! Something went wrong.');
        this.setLiveData(liveData.isUserDetailsFormModalShowing, false);
        this.setLiveData(liveData.isAwaitingEventGenerateResponse, false);
        this.setLiveData(liveData.isAwaitingForRegistrationResponse, false);
        this.setLiveData(liveData.isEventFormInputEnabled, true);
        this.setLiveData(liveData.isEventFormSubmitEnabled, true);
    }

    onEventGenerateResults(chatResponse) {
        console.error('onEventGenerateResults')
        this.setLiveData(liveData.isAwaitingEventGenerateResponse, false);
        if (this.isEventGenerateResultsSuccess(chatResponse)) {
            this.setLiveData(liveData.isEventFormInputEnabled, true);
            this.setLiveData(liveData.isEventFormSubmitEnabled, true);
            this.setLiveData(liveData.chatResponse, chatResponse);
            this.setLiveData(liveData.resultsArea, ResultsAreaUI.Empty);
            console.log('publishing results....')
            console.log('chatResponse: ', chatResponse)
            this.resultsStorePublisher.onFinalResultsReceived(chatResponse);
        }
        else {
            this.showNetworkingErrorState();
        }
    }

    /**
     * @param {*} finalResults the final results payload from the service
     */
    isEventGenerateResultsSuccess(finalResults) {
        if (typeof finalResults === 'string' && finalResults !== '') {
            return true;
        }
        else {
        return false;
    }
}
}
import ViewModel, { LiveData } from 'react-livedata';
import BackPressedReceiver from './BackPressedReceiver';


//declare your livedata properties and initial/default property values here
export const liveData = Object.freeze({
    results: new LiveData(''),
    isShowingRevertResultsModal: new LiveData(false),
    isShowingGoBacktoEventFormModal: new LiveData(false),
});

export default class FinalResultsFormViewModel extends ViewModel {
    /**
     * 
     * @param {React.Component} reactObj 
     * @param {string} originalResults 
     * @param {BackPressedReceiver} backPressedReceiver 
     */
    constructor(reactObj, originalResults, backPressedReceiver) {
        super(reactObj, liveData);
        this.originalResults = originalResults;
        this.backPressedReceiver = backPressedReceiver;
        this.setLiveData(liveData.results, originalResults);
    }
    
    updateResults(results) {
        this.setLiveData(liveData.results, results);
    }
    
    onRevertToOriginalResultsClicked() {
        this.setLiveData(liveData.isShowingRevertResultsModal, true);
    }
    
    onRevertToOriginalResultsConfirmed() {
        this.setLiveData(liveData.results, this.originalResults);
        this.onRevertModalClosed();
    }
    
    onRevertModalClosed() {
        this.setLiveData(liveData.isShowingRevertResultsModal, false);
    }
    
    onBackClicked() {
        this.setLiveData(liveData.isShowingGoBacktoEventFormModal, true);
    }
    
    onGoBackToEventFormConfirmed() {
        this.setLiveData(liveData.isShowingGoBacktoEventFormModal, false);
        this.backPressedReceiver.onBackPressed()
    }

    onGoBackToEventFormModalClosed() {
        this.setLiveData(liveData.isShowingGoBacktoEventFormModal, false);
    }
}
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

class ChannelMixCheckboxPropsSchema {
    constructor() {
        this.isEnabled = false;
        this.text = '';
        this.tooltipText = '';
    }

    /**
     * @param {boolean} isEnabled 
     */
    onEnabledClickedCallback(isEnabled) { }
}

/**
 * @param {ChannelMixCheckboxPropsSchema} props 
 * @returns 
 */
function ChannelMixCheckbox(props) {
    const baseInstructionsOnlyTooltip = (
        <Tooltip>
            {props.tooltipText}
        </Tooltip>
    );
    return (
        <Form.Check
            label={`${props.text}: ${props.tooltipText}`}
            className="form-label form-checkbox-withtext"
            type="checkbox"
            onChange={(e) => {
                props.onEnabledClickedCallback(e.target.checked)
            }}
            defaultChecked={props.isEnabled}
        />
    )
}

export default ChannelMixCheckbox;
import React from 'react';
import { Button, Container, Row, Col, InputGroup, Spinner, ButtonGroup, ToggleButton } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import EmailRegistrationServiceImpl from './EmailRegistrationServiceImpl';
import EventGenerateServiceImpl from './EventGenerateServiceImpl';
import LocalStorageProviderImpl from './localstorage/LocalStorageProviderImpl';
import EventFormViewModel, { INPUT_VOICE_STYLE_MAX, liveData } from './EventFormViewModel';
import getEnvironment from '../config/getEnvironment';
import AdminFormComponentProvider from '../adminForm/AdminFormComponentProvider';
import AdminFormComponentProviderStub from '../adminForm/AdminFormComponentProviderStub';
import ResultsArea from '../ResultsArea';
import TwoSideSliderInput from './TwoSideSliderInput';
import { voiceStyleSliderAccessibilityText } from './AccessibilityTranlations';
import { Alert, Snackbar } from '@mui/material';
import ChannelMixCheckbox from './ChannelMixCheckbox';

class EventForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {};
        const conf = getEnvironment();
        //protection layer for loading in admin
        if (conf.isAdmin) {
            this.adminFormProvider = new AdminFormComponentProvider();
        }
        else {
            this.adminFormProvider = new AdminFormComponentProviderStub();
        }
        this.eventFormViewModel = new EventFormViewModel(this, new EmailRegistrationServiceImpl(), new EventGenerateServiceImpl(), new LocalStorageProviderImpl(), this.props.resultStorePublisher);
    }

    render() {
        const conf = getEnvironment();

        let modalButton = <Button variant="primary" type="submit"
            onClick={() => { this.eventFormViewModel.registerEmail() }}
            disabled={!this.eventFormViewModel.getLiveData(liveData.isModalSubmitEnabled)}
        >
            Submit
        </Button>;

        if (this.eventFormViewModel.getLiveData(liveData.isModalButtonLoading)) {
            modalButton = <Button variant="secondary" type="submit" disabled>
                { }
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                Loading...
            </Button>;
        }
        let adminFormComponent = <></>
        if (conf.isAdmin) {
            adminFormComponent = this.adminFormProvider.getAdminComponent(this.eventFormViewModel);
        }
        const ResultsModal = <>
            <Modal className="modal-label"
                show={this.eventFormViewModel.getLiveData(liveData.isResultsModalShowing)} onHide={async () => { this.eventFormViewModel.onCloseResultsModalClicked() }}>
                <Modal.Body style={{ padding: "0" }} >
                    <ResultsArea
                        resultsAreaType={this.eventFormViewModel.getLiveData(liveData.resultsArea)}
                        chatResponse={this.eventFormViewModel.getLiveData(liveData.chatResponse)}
                        errorText={this.eventFormViewModel.getLiveData(liveData.errorText)}
                        textAreaWidth="50rem"
                    />
                </Modal.Body>
            </Modal>
        </>;

        const UserDetailsFormModalShowing = <>
            <Modal className="modal-label" show={this.eventFormViewModel.getLiveData(liveData.isUserDetailsFormModalShowing)} onHide={async () => { this.eventFormViewModel.onCloseMoreUserInfoModalClicked() }}>
                <Modal.Header closeButton>
                    <Modal.Title >Final Step</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div >We just need a little more information before we can generate your event invitation.</div>
                    <Form>
                        <fieldset disabled={this.eventFormViewModel.getLiveData(liveData.isAwaitingForRegistrationResponse)}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className="modal-label">Business Email</Form.Label>
                                <Form.Control type="email" placeholder="rep_name@saleswriter.com"
                                    value={this.eventFormViewModel.getLiveData(liveData.inputEmail)}
                                    onChange={(e) => {
                                        this.eventFormViewModel.setFormInput(liveData.inputEmail, e.target.value);
                                    }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicUserName">
                                <Form.Label className="modal-label">Name</Form.Label>
                                <Form.Control type="text" placeholder="First Last"
                                    value={this.eventFormViewModel.getLiveData(liveData.inputName)}
                                    onChange={(e) => {
                                        this.eventFormViewModel.setFormInput(liveData.inputName, e.target.value);
                                    }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCompanyUrl">
                                <Form.Label className="modal-label">Number of member of your Sales Team</Form.Label>
                                <Form.Control type="number" placeholder="3? 10?"
                                    value={this.eventFormViewModel.getLiveData(liveData.inputSalesTeamNumber)}
                                    onChange={(e) => {
                                        this.eventFormViewModel.setFormInput(liveData.inputSalesTeamNumber, e.target.value);
                                    }} />
                            </Form.Group>
                        </fieldset>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {modalButton}
                </Modal.Footer>
            </Modal>
        </>;

        return (
            <div>
                <Snackbar
                    open={this.eventFormViewModel.getLiveData(liveData.isPleaseWaitLoadingMessageShowing)}
                >
                    <Alert onClose={() => { this.eventFormViewModel.hideLoadingMessage() }} severity="info" sx={{ width: '100%' }}>
                        Generating an event can take 60 seconds. Thank you for your patience.
                    </Alert></Snackbar>
                {adminFormComponent}
                <div id="event-form">
                    {ResultsModal}
                    {UserDetailsFormModalShowing}
                    <Container>
                        <Row>
                            <Col />
                            <Col sm={12} className="title-text">
                                <h1>Event Creation</h1>
                            </Col>
                            <Col />
                        </Row>
                        <Form>
                            <fieldset disabled={!this.eventFormViewModel.getLiveData(liveData.isEventFormInputEnabled)}>
                                <Row>
                                    <Col>
                                        <h4>Voice Style</h4>
                                        <TwoSideSliderInput 
                                            defaultValue={this.eventFormViewModel.inputSliderStartingVoiceStyle} 
                                            valueBetweenMarks={1}
                                            isEnabled={this.eventFormViewModel.getLiveData(liveData.isEventFormInputEnabled)}
                                            accessibilityValuesForMarks={voiceStyleSliderAccessibilityText}
                                            currentValue={this.eventFormViewModel.getLiveData(liveData.inputVoiceStyle)}
                                            onChange={(newValue) => {
                                                this.eventFormViewModel.setFormInput(liveData.inputVoiceStyle, newValue);
                                            }}
                                            labelAtStart='Salesman' labelAtEnd='Brand'
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ChannelMixCheckbox
                                            isEnabled={this.eventFormViewModel.getLiveData(liveData.inputIsChannelMixEmailEnabled)}
                                            text="Email Chain"
                                            tooltipText="Create a series of three emails."
                                            onEnabledClickedCallback={(isChecked) => {
                                                this.eventFormViewModel.setFormInput(liveData.inputIsChannelMixEmailEnabled, isChecked);
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <ChannelMixCheckbox
                                            isEnabled={this.eventFormViewModel.getLiveData(liveData.inputIsChannelMixLinkedInEnabled)}
                                            text="InMail (LinkedIn)"
                                            tooltipText="Create an additional message for LinkedIn InMail"
                                            onEnabledClickedCallback={(isChecked) => {
                                                this.eventFormViewModel.setFormInput(liveData.inputIsChannelMixLinkedInEnabled, isChecked);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <h2 className='eventform-section'>Overview</h2>
                                <InputGroup size="lg" id="event-name">
                                    <InputGroup.Text id="inputGroup-sizing-lg"
                                    >Event Name</InputGroup.Text>
                                    <Form.Control
                                        placeholder="ACME Corporation Executive Summit for Executives 2023"
                                        aria-label="Event Name"
                                        aria-describedby="inputGroup-sizing-sm"
                                        value={this.eventFormViewModel.getLiveData(liveData.inputEventName)}
                                        onChange={(e) => {
                                            this.eventFormViewModel.setFormInput(liveData.inputEventName, e.target.value);
                                        }}
                                    />
                                </InputGroup>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicCompanyName">
                                            <Form.Label>Company Name</Form.Label>
                                            <Form.Control type="text"
                                                placeholder="ACME Corporation"
                                                value={this.eventFormViewModel.getLiveData(liveData.inputCompanyName)}
                                                onChange={(e) => {
                                                    this.eventFormViewModel.setFormInput(liveData.inputCompanyName, e.target.value);
                                                }} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicCompanyUrl">
                                            <Form.Label>Company URL</Form.Label>
                                            <Form.Control type="text" placeholder="AcmeCorporation.com"
                                                value={this.eventFormViewModel.getLiveData(liveData.inputCompanyURL)}
                                                onChange={(e) => {
                                                    this.eventFormViewModel.setFormInput(liveData.inputCompanyURL, e.target.value);
                                                }} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEventType">
                                            <Form.Label>Event Type</Form.Label>
                                            <Form.Control type="text"
                                                placeholder="Executive Summit, Webinar, Happy Hour"
                                                value={this.eventFormViewModel.getLiveData(liveData.inputEventType)}
                                                onChange={(e) => {
                                                    this.eventFormViewModel.setFormInput(liveData.inputEventType, e.target.value);
                                                }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className="mb-3" controlId="formBasicRegistrationUrl">
                                    <Form.Label>Registration Link</Form.Label>
                                    <Form.Control type="text"
                                        placeholder="AcmeCorporation.com/register"
                                        value={this.eventFormViewModel.getLiveData(liveData.inputRegistrationUrl)}
                                        onChange={(e) => {
                                            this.eventFormViewModel.setFormInput(liveData.inputRegistrationUrl, e.target.value);
                                        }} />
                                </Form.Group>
                                <h2 className='eventform-section'>Details</h2>
                                <Form.Group className="mb-3" controlId="formBasicEventDescription">
                                    <Form.Label>Event Description</Form.Label>
                                    <Form.Control type="text" as="textarea"
                                        style={{ height: '150px' }}
                                        placeholder="Hosted by the iconic Road Runner and Wile E. Coyote, this event is a demonstration of ACME Corporation’s latest outlandish products that fail or backfire catastrophically at the worst possible times."
                                        value={this.eventFormViewModel.getLiveData(liveData.inputEventDescription)}
                                        onChange={(e) => {
                                            this.eventFormViewModel.setFormInput(liveData.inputEventDescription, e.target.value);
                                        }} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicUserBenefit">
                                    <Form.Label>What will attendees learn or gain from attending the event?</Form.Label>
                                    <Form.Control type="text" as="textarea"
                                        style={{ height: '50px' }}
                                        placeholder="Learn how to prevent releasing products that fail catastrophically, and build long-lasting professional relationships with Warner Bros. legends - Road Runner and Wile E. Coyote."
                                        value={this.eventFormViewModel.getLiveData(liveData.inputUserBenefit)}
                                        onChange={(e) => {
                                            this.eventFormViewModel.setFormInput(liveData.inputUserBenefit, e.target.value);
                                        }} />
                                </Form.Group>
                                <h2 className='eventform-section'>When & Where</h2>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEventDate">
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control type="date" name='date_of_birth'
                                                value={this.eventFormViewModel.getLiveData(liveData.inputEventDate)}
                                                onChange={(e) => {
                                                    this.eventFormViewModel.setFormInput(liveData.inputEventDate, e.target.value);
                                                }} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicLocation">
                                            <Form.Label>Location</Form.Label>
                                            <Form.Control type="text"
                                                placeholder="Hollywood, Virtual, Johnny’s Pub"
                                                value={this.eventFormViewModel.getLiveData(liveData.inputEventLocation)}
                                                onChange={(e) => {
                                                    this.eventFormViewModel.setFormInput(liveData.inputEventLocation, e.target.value);
                                                }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEventTimeStart">
                                            <Form.Label>Start Time</Form.Label>
                                            <Form.Control type="time" placeholder=""
                                                value={this.eventFormViewModel.getLiveData(liveData.inputEventTimeStart)}
                                                onChange={(e) => {
                                                    this.eventFormViewModel.setFormInput(liveData.inputEventTimeStart, e.target.value);
                                                }} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEventTimeEnd">
                                            <Form.Label>End Time</Form.Label>
                                            <Form.Control type="time" placeholder=""
                                                value={this.eventFormViewModel.getLiveData(liveData.inputEventTimeEnd)}
                                                onChange={(e) => {
                                                    this.eventFormViewModel.setFormInput(liveData.inputEventTimeEnd, e.target.value);
                                                }} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEventDate">
                                            <Form.Label>Time Zone</Form.Label>
                                            <Form.Control type="text" name='date_of_birth'
                                                value={this.eventFormViewModel.getLiveData(liveData.inputEventTimeZone)}
                                                onChange={(e) => {
                                                    this.eventFormViewModel.setFormInput(liveData.inputEventTimeZone, e.target.value);
                                                }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button variant="primary" type="submit"
                                    onClick={async () => { await this.eventFormViewModel.onSubmitEventFormClicked() }}
                                    disabled={!this.eventFormViewModel.getLiveData(liveData.isEventFormSubmitEnabled)}
                                >
                                    Submit
                                </Button>
                            </fieldset>
                        </Form>
                    </Container>
                </div>
            </div>
        );
    }
};

export default EventForm;

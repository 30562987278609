import axios from 'axios';
import getEnvironment from '../config/getEnvironment';
const conf = getEnvironment();

/**
 * @class EventGenerateServiceImpl calls out to the generate event service for a particular user
 */
class EventGenerateServiceImpl {
    eventGenerationServiceCall(userId, payload) {
        console.log('EventGenerateServiceImpl.eventGenerationServiceCall ', userId);
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: `${conf.baseUrl}/account/${userId}/generateEvent`,
                data: payload
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                console.error(error)
                reject(error);
            });
        });
    }
}

export default EventGenerateServiceImpl;